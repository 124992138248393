<template>
	<div class="page-layout" v-loading.lock="loading">
		<gc-header headerData="smallWaterAnalysis"></gc-header>
		<div class="content">
			<div class="left"><GcRegionTree v-model="form.waterRegionCode" /></div>
			<div class="right">
				<div class="search-modal">
					<gc-custom-search
						class="search"
						key-word="报表类型"
						type="select"
						width="200px"
						:search-option="[
							{ label: '日报', value: 0 },
							{ label: '月报', value: 1 },
						]"
						:search.sync="form.interval"
					></gc-custom-search>
					<gc-custom-search
						class="search"
						width="330px"
						key-word="日期"
						type="date"
						:date-picker-type="form.interval === 0 ? 'daterange' : 'monthrange'"
						:search.sync="form.timeRange"
						:valueFormat="form.interval === 0 ? 'yyyy-MM-dd' : 'yyyy-MM'"
						:clearable="false"
					>
						<template v-slot:icon>
							<i class="iconfont icon-riqi"></i>
						</template>
					</gc-custom-search>
					<gc-custom-search
						class="search"
						key-word="水量"
						type="select"
						width="200px"
						:search-option="[
							{ label: '实际', value: 0 },
							{ label: '取整', value: 1 },
						]"
						:search.sync="form.standard"
					></gc-custom-search>
					<gc-custom-search
						class="search"
						key-word="累计水量区间"
						type="input-range"
						width="320px"
						:search.sync="form.valueRange"
					></gc-custom-search>
					<div class="search">
						<el-button v-click-blur type="primary" class="query" @click="query(1)">查 询</el-button>
						<el-button v-click-blur @click="reset">重 置</el-button>
					</div>
				</div>
				<div class="table-content" v-if="tableData.length > 0">
					<el-table
						ref="tableRef"
						:data="tableData"
						style="width: 100%"
						height="100%"
						:header-cell-style="{
							background: '#EBEDFA',
							color: '#666A80',
							'border-color': 'rgba(170, 178, 193, 0.2)',
							'font-weight': 600,
						}"
						:border="true"
						v-loading="pagination.loading"
					>
						<el-table-column
							v-for="item in columns"
							:key="item.key"
							:label="item.name"
							:width="item.width"
							:align="item.align"
							:fixed="item.fixed"
							:min-width="item.minWidth || 150"
							:show-overflow-tooltip="true"
						>
							<template slot-scope="scope">
								<span>
									{{ !isBlank(scope.row[item.key]) ? scope.row[item.key] : '--' }}
								</span>
							</template>
						</el-table-column>
						<el-table-column :label="form.interval === 0 ? '日用水明细' : '月用水明细'" align="center">
							<el-table-column
								v-for="(el, index) in tableData[0].useVolDetail"
								:key="el.useTime"
								:label="el.useTime.substr(5)"
								align="center"
								:min-width="60"
								:show-overflow-tooltip="true"
							>
								<template slot-scope="scope">
									<span>
										{{
											!isBlank(scope.row.useVolDetail[index].useVol)
												? scope.row.useVolDetail[index].useVol
												: '--'
										}}
									</span>
								</template>
							</el-table-column>
						</el-table-column>
					</el-table>
				</div>
				<gc-empty v-else />
				<gc-pagination
					v-if="tableData.length > 0"
					ref="pagination"
					:total="pagination.total"
					:page-size="pagination.pageSize"
					:currentPage="pagination.currentPage"
					:page-params="pagination"
					@size-change="handleSizeChange"
					@current-page-change="currentPageChange"
				></gc-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { apiGetSmallWatchTableList } from '@/apis/statisticAnalysis.api'
import { isBlank } from '@/utils/validate'

export default {
	data() {
		return {
			pagination: {
				loading: false,
				currentPage: 1,
				total: 1,
				pageSize: 10,
			},
			form: {
				waterRegionCode: null,
				interval: null,
				standard: null,
				timeRange: [],
				valueRange: [],
			},
			tableData: [],
			loading: false,
		}
	},
	computed: {
		tenantId() {
			return this.$store.getters.userInfo.tenantId || null
		},
		columns() {
			return [
				{ key: 'userNo', name: '用户号', fixed: 'left', width: 110 },
				{ key: 'userName', name: '用户名', fixed: 'left', width: 110 },
				{ key: 'deviceNo', name: '表号', fixed: 'left', width: 110 },
				{ key: 'address', name: '地址', fixed: 'left', width: 110 },
				{ key: 'waterRegionName', name: '所属区域', width: 110, fixed: 'left' },
				{ key: 'dataTime', name: '最后采集时间', width: 98, fixed: 'left' },
				{ key: 'totalUseVolume', name: '累计水量', width: 80, fixed: 'left' },
				{
					key: 'averageVolume',
					name: this.form.interval === 0 ? '日均水量' : '月均水量',
					fixed: 'left',
					width: 80,
				},
			]
		},
		userInfo() {
			return this.$store.getters.userInfo
		},
	},
	created() {
		this.initSearchParams()
	},
	watch: {
		'form.waterRegionCode': {
			handler() {
				this.query(1)
			},
		},
		'form.interval': {
			handler(val) {
				if (val === 0) {
					const startTime = this.dayjs().subtract(8, 'day').format('YYYY-MM-DD')
					const endTime = this.dayjs().subtract(1, 'day').format('YYYY-MM-DD')
					this.form.timeRange = [startTime, endTime]
				} else {
					const startTime = this.dayjs().subtract(1, 'month').format('YYYY-MM')
					const endTime = this.dayjs().subtract(0, 'month').format('YYYY-MM')
					this.form.timeRange = [startTime, endTime]
				}
			},
		},
	},

	methods: {
		isBlank,
		initSearchParams() {
			const startTime = this.dayjs().subtract(8, 'day').format('YYYY-MM-DD')
			const endTime = this.dayjs().subtract(1, 'day').format('YYYY-MM-DD')
			this.form.timeRange = [startTime, endTime]
			this.form.interval = 0
			this.form.standard = 0
			this.form.valueRange = []
			const { tenantType, defaultWaterRegionCode } = this.userInfo
			tenantType == 1 && this.$set(this.form, 'waterRegionCode', defaultWaterRegionCode)
		},

		currentPageChange(pageNo) {
			this.query(pageNo)
		},
		handleSizeChange(size) {
			this.pagination.pageSize = size
			this.query(1)
		},
		query(current) {
			this.loading = true
			this.pagination.currentPage = current
			let params = {
				...this.form,
				current: this.pagination.currentPage,
				size: this.pagination.pageSize,
				tenantId: this.tenantId,
			}
			if (!this.form.interval) {
				params['startTime'] = this.form.timeRange[0]
				params['endTime'] = this.form.timeRange[1]
			} else {
				params['startMonth'] = this.form.timeRange[0]
				params['endMonth'] = this.form.timeRange[1]
			}

			if (this.form.valueRange.length > 0) {
				params['totalUseWaterMin'] = this.form.valueRange[0]
				params['totalUseWaterMax'] = this.form.valueRange[1]
			}
			apiGetSmallWatchTableList(params)
				.then(res => {
					this.pagination.total = Number(res.total)
					this.tableData = res.records || []
					this.$nextTick(() => {
						this.$refs.tableRef && this.$refs.tableRef.doLayout()
					})
				})
				.finally(() => {
					this.loading = false
				})
		},

		reset() {
			this.initSearchParams()
			this.query(1)
		},
	},
}
</script>
<style lang="scss" scoped>
.page-layout {
	height: 100%;
	display: flex;
	flex-direction: column;
	.content {
		flex: 1;
		height: 0;
		display: flex;
		.left {
			padding: 20px 12px;
			width: 300px;
			border-right: 1px solid #f1f1f1;
			height: 100%;
			overflow-y: scroll;
		}
		.right {
			flex: 1;
			width: 0;
			height: 100%;
			display: flex;
			flex-direction: column;
			padding: 0 12px 20px 12px;
			.search-modal {
				display: flex;
				flex-wrap: wrap;
				.search {
					margin-top: 20px;
					margin-right: 12px;
					flex-shrink: 0;
				}
			}
			.table-content {
				flex: 1;
				height: 0;
				margin-top: 12px;
				border-radius: 8px 8px 0 0;
				overflow: hidden;
			}
		}
	}
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
	.page-layout {
		.content {
			.left {
				width: 280px;
			}
		}
	}
}
</style>
