var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading.lock",value:(_vm.loading),expression:"loading",modifiers:{"lock":true}}],staticClass:"page-layout"},[_c('gc-header',{attrs:{"headerData":"smallWaterAnalysis"}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"left"},[_c('GcRegionTree',{model:{value:(_vm.form.waterRegionCode),callback:function ($$v) {_vm.$set(_vm.form, "waterRegionCode", $$v)},expression:"form.waterRegionCode"}})],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"search-modal"},[_c('gc-custom-search',{staticClass:"search",attrs:{"key-word":"报表类型","type":"select","width":"200px","search-option":[
						{ label: '日报', value: 0 },
						{ label: '月报', value: 1 } ],"search":_vm.form.interval},on:{"update:search":function($event){return _vm.$set(_vm.form, "interval", $event)}}}),_c('gc-custom-search',{staticClass:"search",attrs:{"width":"330px","key-word":"日期","type":"date","date-picker-type":_vm.form.interval === 0 ? 'daterange' : 'monthrange',"search":_vm.form.timeRange,"valueFormat":_vm.form.interval === 0 ? 'yyyy-MM-dd' : 'yyyy-MM',"clearable":false},on:{"update:search":function($event){return _vm.$set(_vm.form, "timeRange", $event)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"iconfont icon-riqi"})]},proxy:true}])}),_c('gc-custom-search',{staticClass:"search",attrs:{"key-word":"水量","type":"select","width":"200px","search-option":[
						{ label: '实际', value: 0 },
						{ label: '取整', value: 1 } ],"search":_vm.form.standard},on:{"update:search":function($event){return _vm.$set(_vm.form, "standard", $event)}}}),_c('gc-custom-search',{staticClass:"search",attrs:{"key-word":"累计水量区间","type":"input-range","width":"320px","search":_vm.form.valueRange},on:{"update:search":function($event){return _vm.$set(_vm.form, "valueRange", $event)}}}),_c('div',{staticClass:"search"},[_c('el-button',{directives:[{name:"click-blur",rawName:"v-click-blur"}],staticClass:"query",attrs:{"type":"primary"},on:{"click":function($event){return _vm.query(1)}}},[_vm._v("查 询")]),_c('el-button',{directives:[{name:"click-blur",rawName:"v-click-blur"}],on:{"click":_vm.reset}},[_vm._v("重 置")])],1)],1),(_vm.tableData.length > 0)?_c('div',{staticClass:"table-content"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pagination.loading),expression:"pagination.loading"}],ref:"tableRef",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"height":"100%","header-cell-style":{
						background: '#EBEDFA',
						color: '#666A80',
						'border-color': 'rgba(170, 178, 193, 0.2)',
						'font-weight': 600,
					},"border":true}},[_vm._l((_vm.columns),function(item){return _c('el-table-column',{key:item.key,attrs:{"label":item.name,"width":item.width,"align":item.align,"fixed":item.fixed,"min-width":item.minWidth || 150,"show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(" "+_vm._s(!_vm.isBlank(scope.row[item.key]) ? scope.row[item.key] : '--')+" ")])]}}],null,true)})}),_c('el-table-column',{attrs:{"label":_vm.form.interval === 0 ? '日用水明细' : '月用水明细',"align":"center"}},_vm._l((_vm.tableData[0].useVolDetail),function(el,index){return _c('el-table-column',{key:el.useTime,attrs:{"label":el.useTime.substr(5),"align":"center","min-width":60,"show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(" "+_vm._s(!_vm.isBlank(scope.row.useVolDetail[index].useVol) ? scope.row.useVolDetail[index].useVol : '--')+" ")])]}}],null,true)})}),1)],2)],1):_c('gc-empty'),(_vm.tableData.length > 0)?_c('gc-pagination',{ref:"pagination",attrs:{"total":_vm.pagination.total,"page-size":_vm.pagination.pageSize,"currentPage":_vm.pagination.currentPage,"page-params":_vm.pagination},on:{"size-change":_vm.handleSizeChange,"current-page-change":_vm.currentPageChange}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }